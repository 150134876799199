import axios from "axios";

let Api = axios.create({
  baseURL: "https://klobbi.pdpweek.sg/api",
  // baseURL: "http://pdp_admin_24.test/api",
  //  baseURL: "http://127.0.0.1:8000/api",
  //  baseURL: "http://pdp23_admin.test/api",
});

// Api.defaults.withCredentials = true;

export default Api;
